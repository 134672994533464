import { PublicKey } from "@solana/web3.js"
import axios from "axios"

export const NETWORK = 'mainnet-beta'
export const RPC = 'https://ssc-dao.genesysgo.net'
const API_ENDPOINT = 'https://gen1update.herokuapp.com/'
export const PROGRAM_ID = new PublicKey('5eA4bcMTMeWnhqg4wV2LygKMm4jwNvzBJENpeGwNzupD')
export const TOKEN_MINT = new PublicKey('Emberc567ToSP9FgAMuJWHXiQnEKVWrWC6gy64zbsK8u')
export const TOKEN_NAME = 'EMBER'

export const AWAKE_PRICE = 5000
export const BANNED_CIDS = ["bafybeifuoypih5wgwqcdhtpu4jdh73uxwljqcrooleewxzjcs32wint364"]
export const IMG_CID = "https://bafybeie6x3sqr6nfnbleqdk75kekmpqbutzuyzd5nmhxwrfbelm5ndds6q.ipfs.nftstorage.link"

export const api = axios.create({
    baseURL: API_ENDPOINT
})

export const NFT_ALLOWED_SYMBOLS = [
    "METADRAG"
];
export const NFT_ALLOWED_CREATORS = [
    "Gw2mS8kH8SjuX4KxQR7Kkxwau8RaRvGJuMmnY2LBfYo7"
];