import { Connection } from "@solana/web3.js"
import { useEffect, useState } from "react"
import '../css/Awake.css'
import NftMetadata from "../interfaces/NftMetadata"
import PageProps from "../interfaces/PageProps"
import * as anchor from '@project-serum/anchor'
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import getOwnerNfts from "../utils/getOwnerNfts"
import checkNft from "../utils/checkNft"
import getExternalMetadata from "../utils/getExternalMetadata"
import { AWAKE_PRICE, BANNED_CIDS, IMG_CID, NFT_ALLOWED_CREATORS, NFT_ALLOWED_SYMBOLS, PROGRAM_ID, TOKEN_MINT, TOKEN_NAME } from "../vars"
import { Awake, IDL } from "../idl"
import sortNft from "../utils/sortNft"
import NftList from "../components/NftList"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NftChoser from "../components/NftChoser"
import actions from "../actions"
import sendAndConfirmTx from "../utils/sendAndConfirmTx"
import { ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token"
import { getCID } from "../utils/getMetadata"
import getAddressByMetadata from "../utils/getAddressByMetadata"
import Img from "../components/Img"

const AscendPage = (props: PageProps) => {
    const wallet = useAnchorWallet()


    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [program, setProgram] = useState<anchor.Program<Awake>>()
    const [walletNfts, setWalletNfts] = useState<NftMetadata[]>([])
    const [updatedNft, setUpdatedNft] = useState<NftMetadata>()
    const [mints, setMints] = useState<{ nft: NftMetadata | null }>({
        nft: null
    })

    const [loadingStates, setLoadingStates] = useState({
        walletNfts: false,
        walletBooks: false,
        walletScrolls: false
    })


    const loadNfts = async (provider: anchor.Provider, program: anchor.Program<Awake>) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, walletNfts: true, walletBooks: true, walletScrolls: true }))

        const nfts = await getOwnerNfts(provider.connection, wallet.publicKey)
        nfts.forEach(async nft => {
            if (!(checkNft(nft, NFT_ALLOWED_SYMBOLS, NFT_ALLOWED_CREATORS))) {
                return
            }
            const nftData = await getAddressByMetadata(provider.connection, nft.address)
            const nftExternalMetadata = await getExternalMetadata(nftData.address, nft)
            if (!nftExternalMetadata) return

            
            if (BANNED_CIDS.includes(getCID(nft.uri))) return
            setWalletNfts(nfts => [...nfts, nftExternalMetadata].sort(sortNft))
        })

        setLoadingStates(loadingStates => ({ ...loadingStates, walletNfts: false, walletBooks: false, walletScrolls: false }))
    }

    const reset = () => {
        setWalletNfts([])
        setMints({ 
            nft: null,
        })
        setUpdatedNft(undefined)
    }

    const chooseNft = (nft: NftMetadata) => {
        switch (nft.symbol) {
            case 'METADRAG': 
                setMints(mints => ({...mints, nft: nft }))
                break
            default:
                break
        }
    }

    const update = async () => {
        if (!program || !wallet || !provider) return
        if (!mints.nft) return

        const tokenAta = await getAssociatedTokenAddress(TOKEN_MINT, wallet.publicKey)
        let tokenBalance: number;
        try {
            const tokenBalanceData = await props.connection.getTokenAccountBalance(tokenAta)
            tokenBalance = tokenBalanceData.value.uiAmount || 0
        } catch {
            tokenBalance = 0
        }
        if (tokenBalance < AWAKE_PRICE) return toast(`You don\'t have enough $${TOKEN_NAME} tokens. ${AWAKE_PRICE} $${TOKEN_NAME} needed`, { type: 'error' })
        
        try {
            const tx = await actions.update(provider, program, mints.nft)
            tx.recentBlockhash = !tx.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx.recentBlockhash
            tx.feePayer = !tx.feePayer ? provider.wallet.publicKey : tx.feePayer
            const signedTx = await provider.wallet.signTransaction(tx)
            await toast.promise(sendAndConfirmTx(provider.connection, signedTx), {
                pending: 'Confirming Ascend Tx',
                success: 'Drago is succesfully Ascendd',
                error: 'Failed to Ascend Drago'
            })
            setUpdatedNft(mints.nft)
            reset()
            loadNfts(provider, program)
        } catch (e: any) {
            console.log(e)
            toast('Failed to Ascend Drago', { type: 'error' })
        }
    }
    const offerNft = () => {
        if (walletNfts.length === 0) return toast('You do not have The Drago', { type: 'warning' })
        setMints(mints => ({...mints, nft: walletNfts[0]}))
    }

    useEffect(() => {
        if (!wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return

        const newProvider = new anchor.AnchorProvider(props.connection, wallet, { commitment: 'confirmed' })
        const newProgram = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)
        setProgram(newProgram)

        loadNfts(newProvider, newProgram)
    }, [wallet])

    return (
        <div className="Page Ascend">
            {updatedNft ? 
            <div className="banner">
                <h1 className="title animate__animated animate__fadeInUp">{updatedNft.name.toUpperCase()} IS ASCENDED</h1>
                <div className="result">
                    <div className="chooser animate__animated animate__fadeInUp">
                        <Img className="img" src={updatedNft.image} />
                    </div>
                    <img className="arrow animate__animated animate__fadeInUp" src='./img/right-arrow.png' />
                    <div className="chooser animate__animated animate__fadeInUp">
                    <Img className="img" src={`${IMG_CID}/${Number(updatedNft.name.split('#')[1]) - 1}.png`} />
                </div>
                </div>
                <button onClick={() => reset()} className="actionButton animate__animated animate__fadeInUp">CONTINUE</button>
            </div>  : <div className="banner">
                <h3 className="subTitle animate__animated animate__fadeInUp">Enter new era</h3>
                <h1 className="title animate__animated animate__fadeInUp">Ascend your Drago</h1>
                <div className="chooser">
                    <NftChoser wallet={wallet} nft={mints.nft} name='Drago' action={offerNft} />
                </div> 
                {wallet && wallet.publicKey ? <button onClick={update} disabled={!mints.nft} className="actionButton animate__animated animate__fadeInUp">{`ASCEND FOR ${AWAKE_PRICE} ${TOKEN_NAME}`}</button> : <WalletMultiButton className="actionButton animate__animated animate__fadeInUp"/> }
            </div>}
            { wallet && wallet.publicKey ? <>
            <div className="nftView">
                <h2 className="collection">YOUR DRAGOS</h2>
                <NftList isLoading={loadingStates.walletNfts} nfts={walletNfts} chooseNft={chooseNft} chosen={mints.nft} />
            </div>
            </> : null}
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} theme='dark' />
            { wallet && wallet.publicKey ? <img src="./img/down-arrow.png" className="downArrow" /> : null}
        </div>
    )
}

export default AscendPage